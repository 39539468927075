import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { withPrefix } from 'gatsby'

import Helmet from 'react-helmet'

import Seo from '../SeoHead'
import Header from '../Header'
import MobileHeader from '../MobileHeader'
import Footer from '../Footer'
import FeaturedHeader from '../Blog/FeaturedHeader'
import { P } from '../Typography'

const Content = styled.section`
  padding-top: 8rem;
  padding-bottom: 8rem;

  @media (max-width: 750px) {
    padding-top: 0rem;
    padding-bottom: 2rem;
  }
`

const FAQHeader = styled.div`
  width: 100%;
  display: block;
  padding-bottom: 1rem;
  padding-top: 1rem;
  cursor: pointer;
  position: relative;

  &::after {
    content: ' ';
    top: 50%;
    position: absolute;
    right: 0;
    transform: ${({ open }) => open ? 'translateY(-50%) rotate(180deg)' : 'translateY(-50%)'} ;
    width: 0.6rem;
    height: 0.6rem;
    background-image: url(${withPrefix('/images/FAQArrow.png')});
    background-size: contain;
    background-position: center center;
  }
`
const FAQContainer = styled.div`
  border-bottom: 1px solid #403269;
`
const FAQInfo = styled.div`
  overflow: hidden;
  ${({ open }) => open ? 'padding: 2rem; padding-top: 0rem; height: auto' : 'padding: 0; height: 0;'}
  padding-top: 0;

  a {color: var(--lilac)}
  a:hover{text-decoration: underline}

  & * {
    font-weight: 300;
  }
`

class FAQItem extends PureComponent {
  state = {
    open: false
  }

  toggleOpen = () => {
    this.setState({ open: !this.state.open })
  }

  render() {
    const { title, content } = this.props
    const { open } = this.state
    return (
      <FAQContainer>
        <FAQHeader onClick={this.toggleOpen} open={open}>
          <P>{title}</P>
        </FAQHeader>
        <FAQInfo className='faq-info' open={open}>
          <P>
            <span dangerouslySetInnerHTML={{ __html: content }} />
          </P>
        </FAQInfo>
      </FAQContainer>
    )
  }
}

FAQItem.defaultProps = {
  title: 'FAQ Title',
  content: 'FAQ Content'
}

const FAQParent = styled.div`
  width: 100%;
  max-width: 56rem;
  margin: 2rem auto;
  padding: 2rem;
  margin-bottom: 2rem;
`

const Main = styled.main`
  min-height: 100vh;
  position: relative;
`

export default class FAQ extends PureComponent {
  state = {
    windowWidth: false
  }

  componentDidMount() {
    this.attachEventListeners()
    this.handleResize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  attachEventListeners() {
    window.addEventListener('resize', this.handleResize)
  }

  handleResize = e => {
    this.setState({ windowWidth: window.innerWidth })
  }


  render() {
    const { content } = this.props
    const { windowWidth } = this.state
    return (
      <Main>
        <Seo />
        <Helmet>
          <title>Skyweaver Frequently Asked Questions - A Trading Card Game from Another Dimension</title>
        </Helmet>

        <Header routeToHomepage />
        <MobileHeader routeToHomepage />

        <Content>
          <FeaturedHeader>
            Frequently Asked Questions
          </FeaturedHeader>

          <FAQParent name='FAQ Content'>
            {content.map((faq, i) => <FAQItem key={i} {...faq} />)}
          </FAQParent>
        </Content>

        <Footer windowWidth={windowWidth} bottom='0' />
      </Main>
    )
  }
}
