import React, { Component } from 'react'

import data from 'data'

import '../css/main.css'
import '../css/fonts.css'

import Faq from '../components/FaqPage'

export default class Redeem extends Component {
  render() {
    return (
      <>
        <Faq content={data.FAQPage.content} />
      </>
    )
  }
}
